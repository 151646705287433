.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 100vh;
}

.App-link {
  color: #61dafb;
}

.App-title {
  justify-content: flex-start;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.App-target {
  font-size: calc(10px + 4vmin);
  font-weight: bolder;
  color: white;
}

.App-target-title {
  font-size: calc(10px + 1vmin);
  font-weight: bold;
  color:darkgray;
}

.App-tile {
  border-radius: 15px;
  width: 50px;
  height: 50px;
  margin: 5px;

  /* Main shadow */
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
}

.App-tile-number {
  border-style:dashed;
}

.App-tile-operation {
  background-color:peru;
  padding-top: 5px;
}

.App-number-tray {
  padding: 10px;
}

.App-vertical-break {
  height: calc(10px + 5vh);
  width: 100%;
  background-color: transparent;
  display: block;
}
